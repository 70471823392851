body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {  
  background-color: #e7e7ea;
}

.breadcrumbs {
  padding: 0;
  margin: 5px;
  margin-left: 75px;  
}

.content {
  margin: 15px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 75px;
  margin-right: 75px;  
}

.content-body {
  padding: 10px;
  background-color: white;
  color: #2a3e54;
}

.login {
  position: relative;
  margin: 15px;
  padding: 15px;
  border-radius: 5px;
  background-color: #2a3e54;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5);
}

.login:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(42, 62, 84, 0);
	border-bottom-color: #2a3e54;
	border-width: 10px;
	margin-left: -10px;
}

.navbar {
  border-radius: 5px;
  background-color: #2a3e54;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.5);
}

.header {
  
}

.footer {
  padding: 5px;
  padding-left: 75px;
  padding-right: 75px;
  font-size: 10px;
}
